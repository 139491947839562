import '@satellite/polyfills';

//VUE
import { createApp } from 'vue';
import { plugin as featureFlagPlugin } from '@loadsmart/vue-feature-flags';

// APP
import App from './App.vue';
import config from './config/config';
import { initStore } from './store';

// Mixins
import localStorageMixin from '@satellite/components/mixins/localStorageMixin';
import mappedGetters from '@/components/mixins/mappedGetters';
import mappedStates from '@/components/mixins/mappedStates';
import utilityMixin from '@/components/mixins/utilityMixin';

// Plugins
import AxiosPlugin from '@satellite/plugins/AxiosPlugin';
import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import ConfirmDialogPlugin from '@satellite/plugins/ConfirmDialog/ConfirmDialogPlugin';
import SocketPlugin from '@satellite/plugins/SocketPlugin';
import util from '@satellite/plugins/util';
import validation from '@satellite/plugins/Validator';
import initSentry from '@satellite/plugins/sentry';
import vuetify from './plugins/vuetifyPlugin';
import Hotjar from 'vue-hotjar';
import Notifications from '@kyvg/vue3-notification';
import velocity from 'velocity-animate';
import featureFlagConfig from './plugins/featureFlagPlugin';
import initializeRouter from '@/routes.js';

// Deps
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import * as Core from '@nova/core';
import VueApexCharts from 'vue3-apexcharts';
import moment from 'moment-timezone';

import warehouseService from '@satellite/services/warehouse-service';
import userService from '@satellite/services/user-service';
import dockService from '@satellite/services/dock-service';
import appointmentService from '@satellite/services/appointment-service';
import warehouseGroupService from '@satellite/services/warehouse-group-service';
import companyService from '@satellite/services/company-service';
import MixpanelPlugin from '@satellite/plugins/MixpanelPlugin.js';

import { useEventHub } from '@/composables/index.js';
import { Settings } from 'luxon';

// Set default locale so working with Weekdays does not break for different locales
Settings.defaultLocale = 'en-US';

const app = createApp(App);

// Init Plugins
window.momentjs = moment;
AxiosPlugin.init('/login', 'Helios', app);

// MODULES
// app.use(VueMask);
app.use(VueApexCharts);
app.use(Notifications, { velocity });
app.use(featureFlagPlugin, { config: featureFlagConfig });

// Vue Global Properties
app.config.globalProperties.util = util;
app.config.globalProperties.novaCore = Core;
app.config.globalProperties.$validator = validation;
app.config.globalProperties.config = config;
app.config.globalProperties.$globalLoading = false;

app.config.globalProperties.notify = utilityMixin.methods.notify;
app.config.globalProperties.setDirtyStatus = utilityMixin.methods.setDirtyStatus;
app.config.globalProperties.checkDirtyConfirmation = utilityMixin.methods.checkDirtyConfirmation;

// Services
app.config.globalProperties.services = {
  warehouse: warehouseService,
  warehouseGroup: warehouseGroupService,
  user: userService,
  dock: dockService,
  appointment: appointmentService,
  company: companyService
};

// Vue Global Mixins
app.mixin(mappedStates);
app.mixin(mappedGetters);
app.mixin(utilityMixin);
app.mixin(localStorageMixin);

app.config.globalProperties.mixpanel = new MixpanelPlugin();
app.config.globalProperties.mixpanel.init();

ConfirmDialogPlugin.init(app);

initStore().then(async store => {
  app.use(store);
  app.config.globalProperties.sockets = new SocketPlugin(app);
  store.$app = app;

  /**
   * Create a bound notify function with access to novaCore,
   * so we can use it in the store, like so: `store.notify('message', 'success')`.
   */
  store.notify = (message, type = 'success', options = {}) => {
    utilityMixin.methods.notify.call(
      {
        novaCore: Core,
        $notify: app.config.globalProperties.$notify
      },
      message,
      type,
      options
    );
  };

  app.use(VueTelInput);

  const routes = await initializeRouter(store, app);
  app.use(routes);

  const eventHub = useEventHub();
  app.config.globalProperties.$eventHub = eventHub;

  initSentry(app, routes, config);

  // Import and use vuetify
  app.use(vuetify);
  app.mount('#app');
});

// Hotjar
if (config.hotjar_site_id) {
  app.use(Hotjar, {
    id: config.hotjar_site_id
  });
}

/**
 * HACK: this is necessary to access the app instance outside of vue context.
 * For example, in order to use the app context to access unleash flags on the
 * stores we need to use the `app.runWithContext` method:
 * ```
   const enabled = window.app?.runWithContext(() => useClient()?.isEnabled?.('group-events-by-status-month-view'));
 * ```
 * @see https://vuejs.org/api/application#app-runwithcontext
 */
window.app = app;

export default app;
